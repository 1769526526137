<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import {Head} from "@inertiajs/inertia-vue3";
import {ref} from "vue";
const suggestions = ref([
    { text: 'Return to Home', link: '/' },
    { text: 'Explore Popular Content', link: '/explore' }
]);
</script>
<template>
    <Head>
        <title>Not Found</title>
    </Head>
    <authenticated-layout>
        <div class="min-h-screen bg-black">
            <div class="max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div class="max-w-xl mx-auto text-center">
                    <!-- Error Number -->
                    <p class="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>

                    <!-- Main Message -->
                    <h1 class="mt-2 text-4xl font-extrabold text-gray-100 tracking-tight sm:text-5xl">
                        Oops! Lost in the Feed
                    </h1>

                    <!-- Subtext -->
                    <p class="mt-4 text-lg text-gray-500">
                        The content you're looking for seems to have wandered off. Don't worry, there's plenty more to discover!
                    </p>

                    <!-- Suggestions Box -->
                    <div class="mt-12 bg-white rounded-lg shadow-lg p-6">
                        <h2 class="text-lg font-medium text-gray-900 mb-4">Here's what you can do:</h2>
                        <ul class="space-y-4">
                            <li v-for="(suggestion, index) in suggestions" :key="index">
                                <a
                                    :href="suggestion.link"
                                    class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-full justify-center transition-colors duration-150"
                                >
                                    {{ suggestion.text }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- Additional Help -->
                    <p class="mt-6 text-base text-gray-500">
                        Need help?
                        <a href="/support" class="text-indigo-600 hover:text-indigo-500">
                            Contact our support team
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </authenticated-layout>
</template>

